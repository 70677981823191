@charset "UTF-8";
/*@import url(https://fonts.googleapis.com/css?family=Abel);*/


/* reset.css
====================================== */

/*!
 * Bootstrap Reboot v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */

* {
  box-sizing: border-box;
  &::before, &::after {
    box-sizing: border-box;
  }
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-size: 62.5%;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  //margin-bottom: 1rem;
}
abbr {
  &[title], &[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
		//text-decoration-skip-ink: none;
  }
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: .5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b, strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub { bottom: -.25em; }
sup { top: -.5em; }
a {
  text-decoration: none;
  background-color: transparent;
  &:hover {
    text-decoration: underline;
  }
  &:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
    &:focus {
      color: inherit;
      text-decoration: none;
      outline: 0;
    }
  }
}
pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
	pointer-events: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
  &:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
}
input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button, input {
  overflow: visible;
}
button {
  text-transform: none;
}
select {
  text-transform: none;
  word-wrap: normal;
}
button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}
button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input {
  &[type="radio"], &[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }
  &[type="date"], &[type="time"], &[type="datetime-local"], &[type="month"] {
    -webkit-appearance: listbox;
  }
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"] {
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    height: auto;
  }
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
/*# sourceMappingURL=bootstrap-reboot.css.map */


/*  / reset.css
====================================== */

body {
  font-family: '游ゴシック', YuGothic, 'メイリオ', Verdana, 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.6;
  letter-spacing: .1em;
  color: #191716;
  background-color: #fff;
}
a {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}


/* mediaquery
------------------------------------------------------------*/

$sp: 480px;  // Smartphone
$tab: 750px; // Tablet
$pc: 960px; // PC
$fpc: 1280px; // FULL PC

@mixin ssp {
	@media screen and ( max-width: ($tab)) {
		/* ===== low480px ===== */
		@content;
	}
}

@mixin sp {
	@media screen and (min-width: ($sp)) and ( max-width: ($tab)) {
	/* ===== 480px〜600px ===== */
		@content;
	}
}

@mixin tab {
	@media screen and (min-width: ($tab)) and ( max-width: ($pc)) {
		/* ===== 600px〜960px ===== */
		@content;
	}
}

@mixin pc {
	@media screen and (min-width: ($pc)) and ( max-width: ($fpc)) {
		/* ===== 960px〜1280px ===== */
		@content;
	}
}
@mixin fpc {
	@media screen and (min-width: ($pc)) {
		/* ===== over1280px ===== */
		@content;
	}
}


/* ancher_link
------------------------------------------------------------*/

.scroll_button_btm a{
	width: 30px;
	height: 10vw;
	padding: 20px;
	position: fixed;
	top: 10vw;
	left: 7vw;
	display: block;
	background: url(../img/icon.png) -1.5vw -17vw/22vw auto no-repeat;
	z-index: 9999;
 }


 /* fadeIn
 ------------------------------------------------------------ */
 .fadein {
	opacity : 0;
	transform : translate(0, 50px);
	transition : all 1500ms;
 }
 .fadein.scrollin {
	opacity : 1;
	transform : translate(0, 0);
 }


 /* humbugger menu
 ------------------------------------------------------------ */
 @import url(https://fonts.googleapis.com/css?family=Vollkorn|Roboto);

 $color-background: #fff;
 $color-main: #000;
 $color-active: #000;
 $color-link: #000;

 $button-height: 27px;
 $button-width: 35px;

.button_container {
	position: absolute;
	top: 5rem;
	right: 5vw;
	height: 27px;
	width: 35px;
	cursor: pointer;
	z-index: 9999;
	transition: opacity .25s ease;
	@include tab {
		position: absolute;
		top: 4rem;
    right: 10vw;
		height: 27px;
    width: 55px;
		cursor: pointer;
		z-index: 100;
		transition: opacity .25s ease;
	};
	@include ssp {
		top: 7vw;
		right: 8vw;
	}
	&:hover {
		opacity: .7;
	}
	&.active {
		.top {
			transform: translateY(11px) translateX(0) rotate(45deg);
			background: $color-active;
			@include tab {
				transform: translateY(16px) translateX(0) rotate(45deg);
			};
		}
		.middle {
			opacity: 0;
			background: $color-active;
		}
		.bottom {
			transform: translateY(-11px) translateX(0) rotate(-45deg);
			background: $color-active;
			@include tab {
				transform: translateY(-16px) translateX(0) rotate(-45deg);
			};
		}
	}
	span {
		background: $color-main;
		border: none;
		height: 2px;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		transition:  all .35s ease;
		cursor: pointer;
		&:nth-of-type(2) {
			top: 11px;
			@include tab {
	   	 top: 16px;
			};
		}

		&:nth-of-type(3) {
			top: 22px;
			@include tab {
		    top: 32px;
			};
		}
	}
}
.overlay {
	position: fixed;
	background: #fff;
	top: 0;
	left: 0;
	width: 100%;
	height: 0%;
	opacity: 0;
	visibility: hidden;
	transition: opacity .35s, visibility .35s, height .35s;
	overflow: hidden;
	&.open {
		opacity: 1;
		visibility: visible;
		height: 100%;
		z-index: 99;
		li {
			animation: fadeInRight .5s ease forwards;
			animation-delay: .35s;
			&:nth-of-type(2) {
				animation-delay: .4s;
			}
			&:nth-of-type(3) {
				animation-delay: .45s;
			}
			&:nth-of-type(4) {
				animation-delay: .50s;
			}
		}
	}
	nav {
		position: relative;
		height: 70%;
		top: 50%;
		transform: translateY(-50%);
		font-size: 50px;
		font-family: 'Vollkorn', serif;
		font-weight: 400;
		text-align: center;
		@include tab {
			height: 90%;
		};
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 25vw auto 0;
		display: inline-block;
		position: relative;
    height: 80%;
		li {
			display: block;
			height: 25%;
			height: calc(100% / 5);
			min-height: 50px;
			position: relative;
			opacity: 0;
			a {
				display: block;
				position: relative;
				color: $color-link;
				text-decoration: none;
				overflow: hidden;
				&:hover:after,
				&:focus:after,
				&:active:after {
					width: 100%;
				}
				&:after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 50%;
					width: 0%;
					transform: translateX(-50%);
					height: 3px;
					background: $color-link;
					transition: .35s;
				}
				@include ssp {
			    font-size: 7.9713vw;
				}
			}
		}
	}
}
@keyframes fadeInRight {
	0% {
		opacity: 0;
		left: 20%;
	}
		100% {
		opacity: 1;
		left: 0;
	}
}
.toggleA {
	position: fixed !important;
}


.clone-nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	transition: .5s;
	transform: translateY(-100%);
	ul {
		width: 100%;
    background: #fff;
    margin: 0 0 0 auto;
		display: flex;
		position: relative;
		opacity: 0.95;
		li {
			padding: 2.3vw 2vw;
			&:last-of-type {
				a {
					span.bd_Y {
						content: "";
						width: 100%;
						height: auto;
						position: relative;
						left: -2vw;
						border-left: 1px solid #000;
					}
				}
			}
			a {
				width: 100%;
				height: auto;
				display: block;
			}
			.cool-link {
				color: #000;
				text-decoration: none;
			}
			.cool-link::after {
				content: '';
				width: 0;
				height: 2px;
				display: block;
				background: #000;
				transition: width .3s;
			}
			.cool-link:hover::after {
				width: 100%;
			}
		}
	}
}


 /* scrollbar
 ------------------------------------------------------------ */
 ::-webkit-scrollbar{
 	width: 12px;
 }
 ::-webkit-scrollbar-track{
 background: #fff;
 border-left: solid 1px #ececec;
 }
 ::-webkit-scrollbar-thumb{
 background: #000;
 border-radius: 10px;
 box-shadow: inset 0 0 0 2px #fff;
 }


/* align
------------------------------------------------------------*/

h2.text-center,div.text-center  {
	margin: 20px 0;
	text-align: center;
	font-weight: bold;
}


/* layout
------------------------------------------------------------*/

img.block-center, section div.container, #skills i {
	display: block;
	margin-right: auto;
	margin-left: auto;
}


/* font
------------------------------------------------------------*/

h1,h2,h3,a,p,li,ul {
	font-family: "futura-pt", sans-serif;
	font-weight: 500;
	font-style: normal;
}
/*
.uppercase, section h2, #skills h3, #works .works-box a .works-box-inner .button-readmore {
  text-transform: uppercase;
}
*/


/* display: none;
------------------------------------------------------------*/

.sp {
	@include ssp {
		display: block !important;
	}
	@include tab {
		display: none !important;
	}
	@include fpc {
		display: none !important;
	}
}
.tab {
	@include ssp {
		display: none !important;
	}
	@include tab {
		display: block !important;
	}
	@include fpc {
		display: none !important;
	}
}
.pc {
	@include fpc {
		display: block !important;
	}
	@include tab {
		display: none !important;
	}
	@include ssp {
		display: none !important;
	}
}
.pc_tab {
	@include ssp {
		display: none !important;
	}
	@include tab {
		display: block !important;
	}
	@include fpc {
		display: block !important;
	}
}
.pc_sp {
	@include ssp {
		display: block !important;
	}
	@include tab {
		display: none !important;
	}
	@include fpc {
		display: block !important;
	}
}
.sp_tab {
	@include ssp {
		display: block !important;
	}
	@include tab {
		display: block !important;
	}
	@include fpc {
		display: none !important;
	}
}
.pc_inline {
	display: inline-block !important;
	@include fpc {
		display: inline-block !important;
	}
	@include tab {
		display: none !important;
	}
	@include ssp {
		display: none !important;
	}
}
.pc_tab_inline {
	display: inline-block !important;
	@include fpc {
		display: inline-block !important;
	}
	@include tab {
		display: block !important;
	}
	@include ssp {
		display: none !important;
	}
}


/* ------------------------------------------------------------
	header
------------------------------------------------------------ */

.header {
	width: 100%;
	@include tab {
		.logo {
			a {
				width: 35%;
				height: auto;
				position: absolute;
				top: 1.5vw;
				left: 3vw;
				display: block;
				z-index: 100;
				img {
					width: 100%;
			    height: auto;
			    display: block;
				}
			}
		}
	};
	@include tab {
		.logo {
			a {
				top: 3.5vw;
				left: 5vw;
			}
		}
	};
	@include ssp {
		.logo {
			width: 50%;
			@include ssp {
				width: 100%;
				height: 20vw;
				margin: 0;
				padding: 0;
				background: #fff;
			};
			a {
				width: 50%;
				height: auto;
				position: absolute;
		    top: 4.5vw;
				left: 3vw;
				display: block;
				z-index: 100;
				img {
					width: 100%;
					height: auto;
					display: block;
				}
			}
		}
	}
	.header_container {
		background: #fff;
		position: relative;
		z-index: 2;
		.header_area {
	    width: 75%;
			height: 112px;
			margin: 0 auto;
			display: flex;

			@include tab {
				width: 90%;
			}
			.logo_contents {
				h1 {
					margin-bottom: 0;
	        position: relative;
			    top: 24px;
					a {
						width: 100%;
						height: auto;
						display: block;
						img {
							width: 25rem;
					    height: auto;
					    display: block;
						}
					}
				}
				h1.logo_p {
					position: relative;
					top: 24px;
				}
			}
			nav {
		    margin-left: auto;
				ul {
          margin-bottom: 0;
					display: flex;
					li {
				    padding: 4rem 2.5rem;
						&:last-of-type {
							a {
								span.bd_Y {
									content: "";
									width: 100%;
									height: auto;
									position: relative;
							    left: -25px;
									border-left: 1px solid #000;
								}
							}
						}
						a {
							width: 100%;
							height: auto;
							display: block;
						}
						.cool-link {
							font-size: 1.5rem;
					    font-size: 15px;
							color: #000;
							text-decoration: none;
						}
						.cool-link::after {
							content: '';
							width: 0;
							height: 2px;
							display: block;
							background: #000;
							transition: width .3s;
						}
						.cool-link:hover::after {
							width: 100%;
						}
					}
				}
			}
		}
	}
}

.is-show {
  transform: translateY(0) !important;
	z-index: 10;
}
.header_nav_s {
	width: 100%;
	height: 10vw;
	position: fixed;
	z-index: 100;
	background: #fff;
	.header_nav_container_s {
		h1.logo{
			margin: 0;
		}
	}
	@include tab {
    height: 15vw;
	};
}


/* ------------------------------------------------------------
	main
------------------------------------------------------------ */

.main {
	width: 100%;
	height: auto;
	background: #ebebeb;
	position: relative;
	img {
		width: 100%;
		height: auto;
		display: block;
	}
	.main_container {
    height: 78vh;
		@include tab {
			height: 78rem;
		};
		@include ssp {
			height: 140.5vw;
		};
		.line_a {
			width: 68%;
			position: absolute;
	    top: 2.8vw;
	    right: 0;
	    z-index: 1;
			@include tab {
				top: 23.8vw;
				height: 4vw;
			};
			@include ssp {
				width: 48%;
				top: 35vw;
			};
		}
	}
	.main_img_container {
		width: 100%;
		height: 73vh;
		@include tab {
	    height: 78rem;
		};
		@include ssp {
	    height: 140vw;
		};
		.main_img {
			content: "";
			width: 100%;
      height: 78vh;
			display: block;
			position: absolute;
			background: url(../img/main.jpg) center center/ cover no-repeat;
			@include tab {
				width: 100%;
        height: 78rem;
		    display: block;
		    background: url(../img/main.jpg_t.jpg) bottom center/100% auto no-repeat;
			};
			@include ssp {
				width: 100%;
		    height: 140vw;
		    display: block;
		    background: url(../img/main.jpg_s.jpg) bottom center/100% auto no-repeat;
			};
		}
	}
	.main_text {
		position: absolute;
    top: 8rem;
    left: 13vw;
		@include tab {
			top: 20rem;
	    left: 8vw;
		};
		@include ssp {
	    top: 45vw;
	    left: 0;
		};
		svg {
			width: 24vw;
			height: 24vw;
			position: absolute;
	    top: -14rem;
	    left: -3.4vw;
			@include tab {
				width: 42vw;
				height: 42vw;
				top: -13vw;
		    left: -10vw;
			};
			@include ssp {
				width: 58vw;
				height: 58vw;
				top: -12rem;
				left: -15vw;
			};
		}
		h2 {
			padding-bottom: 10px;
	    border-bottom: 1px solid;
	    font-size: 2.8vw;
	    position: relative;
	    display: inline;
	    font-family: "futura-pt", sans-serif;
			span.main_text_img {
				content: "";
		    width: 18vw;
		    height: 6rem;
		    display: block;
		    position: relative;
		    background: url(../img/imagination.png) center center/18vw auto no-repeat;
				@include tab {
					width: 25vw;
					height: 8vw;
					background: url(../img/imagination.png) center center/25vw auto no-repeat;
				};
				@include tab {
					width: 24vw;
					height: 8vw;
					background: url(../img/imagination.png) center center/24vw auto no-repeat;
				};
				&::after {
					content: "";
					width: 100%;
			    height: 7vh;
					display: block;
			    border-bottom: 1.5px solid #000;
					@include tab {
						height: 5.5rem;
					};
				}
			}
			@include ssp {
				span.main_text_img {
					width: 40vw;
					height: 9vw;
			    left: 8vw;
					background: url(../img/imagination.png) center center/40vw auto no-repeat;
					&::after {
		        height: 10vw;
					}
				}
			};
		}
		.main_textbox {
	    margin-top: 2.6rem;
			margin-left: 3vw;
			@include ssp {
				margin: 2vw 0 0 8vw;
			};
			ul {
				margin-bottom: 1vw;
				position: relative;
				display: flex;
				@include tab {
			    margin-bottom: 2vw;
				};
				li {
			    padding: 10px 1.5vw;
					font-size: 30rem;
					font-size: 30px;
					@include tab {
				    font-size: 3.5vw;
					};
					&:first-of-type{
						padding-left: 0;
					}
					.bd_skX {
						height: 1vw;
						position: relative;
						top: 0;
						right: 1.5vw;
						display: inline-block;
						-webkit-transform: skewX(150deg);
						-moz-transform: skewX(150deg);
						transform: skewX(150deg);
						border-left: 2px solid ;
						@include tab {
							height: 2.5vw;
						};
						span {
							display:block;
							transform: skewX(-150deg);
							padding:0 10px;
						}
					}
				}
			}
			@include ssp {
				ul {
					margin-bottom: 0;
					li {
						padding: 4vw 2.5vw 0;
						font-size: 5vw;
						.bd_skX {
							height: 4vw;
							top: 0.8vw;
							right: 2.5vw;
						}
					}
				}
			}
			.fv_text_a {
				content: "";
		    width: 53rem;
		    height: 15rem;
		    display: block;
		    position: relative;
		    background: url(../img/fv_txt.png) center center/53rem auto no-repeat;
				@include tab {
					width: 52vw;
			    height: 14vw;
					background: url(../img/fv_txt.png) center center/52vw auto no-repeat;
				};
				@include ssp {
					width: 68vw;
					height: 22vw;
					background: url(../img/fv_txt.png) center center/68vw auto no-repeat;
			    z-index: 1;
				};
			}
		}
	}
	.servic_container {
		position: relative;
    padding-top: 15vw;
		@include tab {
      padding: 35rem 0 12rem 0;
		};
		@include ssp {
			padding-top: 10rem;
		};
		.logo_title_b {
			width: 100%;
			height: 12vw;
			margin: 0 auto;
			display: block;
			position: absolute;
	    top: -6vw;
			left: 0;
			background: url(../img/logo_b.png) center/72vw auto no-repeat;
			z-index: 1;
			@include tab {
				height: 22vw;
  	    top: -8rem;
				background: url(../img/logo_c.png) center/92vw auto no-repeat;
			};
			@include ssp {
				height: 24vw;
				top: -12vw;
				background: url(../img/logo_c.png) center/96vw auto no-repeat;
			};
		}
		.cirlce_icon {
			position: absolute;
			top: -34.6vw;
			right: 6.5vw;
			@include tab {
	    	top: -45.6vw;
			};
			svg {
				&:first-of-type {
					width: 22vw;
					height: 22vw;
					position: absolute;
					top: 7vw;
					right: -2.4vw;
			    z-index: 1;
					@include tab {
						width: 27vw;
						height: 36vw;
						top: -6vw;
						right: -6.5vw;
					};
					@include ssp {
						width: 50vw;
						height: 50vw;
				    top: -43vw;
						right: -6.4vw;
						z-index: 0;
					};
				}
				&:last-of-type {
					width: 15vw;
					height: 15vw;
					position: absolute;
					top: 21.8vw;
					left: -27.9vw;
					z-index: 0;
					@include tab {
						width: 27vw;
						height: 27vw;
				    top: 21vw;
						left: -33vw;
					};
					@include ssp {
						width: 36vw;
						height: 36vw;
				    top: -4vw;
						left: -45vw;
						z-index: 0;
					};
				}
			}
		}
		.service_img_box {
			position: relative;
			@include ssp {
		    margin-bottom: 30vw;
		    padding-top: 0;
			};
			.service_img {
				width: 40vw;
		    height: 30vw;
		    display: block;
		    background: url(../img/image_1.jpg) center center/cover no-repeat;
		    position: absolute;
		    top: -14.8vw;
		    right: 0;
		    border: 1px solid;
		    border-right: none;
		    z-index: 0;
				@include tab {
					width: 85vw;
					height: 60vw;
	        top: -25vw;
				};
				@include ssp {
					width: 85vw;
					height: 85vw;
					margin: 0 0 0 auto;
					position: static;
				};
				&::before {
					content: "";
					width: 102%;
					height: 1vw;
					position: absolute;
					top: -2vw;
					right: 0;
					border-bottom: 1px solid #000;
					display: block;
					@include tab {
						display: none;
					};
					@include ssp {
						width: 70%;
						top: -4vw;
					};
				}
			}
			.circle_d {
				width: 23rem;
				height: 23rem;
				@include tab {
					width: 27rem;
					height: 29rem;
					position: relative;
			    top: 14vw;
					right: 0;
					margin-left: auto;
				};
				@include ssp {
					width: 27vw;
					height: 40vw;
					position: absolute;
					top: 60vw;
					right: 0;
				};
			}
			.line_b {
				width: 15vw;
				height: 15vw;
				position: absolute;
				top: -40rem;
				left: 22vw;
				@include tab {
					top: -76rem;
					left: 3rem;
				};
				@include ssp {
					width: 18vw;
	        top: -43rem;
					left: 0;
					transform: matrix3d(2, -2.5, 0, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 240, 0, 1);
				};
			}
		}
		.circle_box {
			position: relative;
			.circle_ef {
				width: 23rem;
		    height: 23rem;
		    position: absolute;
		    top: -30vw;
		    left: 0;
			}
		}
	}
}


/* ------------------------------------------------------------
	main_service
------------------------------------------------------------ */

.service {
	padding-top: 24vh;
	margin-top: -14vh;
	text-align: center;
	@include tab {
		margin-top: -10vw;
		padding-top: 10vw;
	};
	@include ssp {
		padding-top: 25vw;
		margin-top: -25vw;
	};
	h2 {
    margin: 0 0 6rem;
		font-size: 33px;
		font-size: 3.3rem;
		@include tab {
      margin: 12vw 0 9rem;
			font-size: 5vw;
		};
		@include ssp {
	    margin: 0 0 12vw;
			font-size: 24px;
			font-size: 2.4rem;
		};
		small {
			margin-bottom: -6rem;
			display: block;
			font-size: 1.4rem;
			font-size: 14px;
			font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
			@include tab {
				margin-bottom: -8vw;
			};
			@include ssp {
				font-size: 10px;
				font-size: 1rem;
		    margin-bottom: -11vw;
			};
		}
	}
}
.service_text_box {
	width: 100%;
  position: relative;
	display: flex;
	.line_b {
		width: 30rem;
		height: 20rem;
		position: absolute;
		top: -39rem;
		left: 25rem;
		@include tab {
	    top: -107vw;
			left: -2vw;
			width: 30vw;
			height: 25vw;
		};
		@include ssp {
			width: 18vw;
			top: -43rem;
			left: 0;
			transform: matrix3d(2, -2.5, 0, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 240, 0, 1);
		};
	}
	.circle_c {
		width: 26rem;
		height: 26rem;
		position: absolute;
		top: -9rem;
    left: 15vw;
		z-index: 0;
		@include tab {
      top: -18rem;
			left: 0;
		};
		@include ssp {
	    top: -20vw;
			left: 0;
		};
	}
	.service_text {
    width: 60%;
		margin: 0 auto 5vw;
		position: relative;
		@include tab {
			width: 85%;
	    margin: 0 auto 8vw;
		};
		@include ssp{
			width: 90%;
			margin: 0 auto;
		};
		h3.main_title {
			span.service_b {
				content: "";
		    width: 42rem;
		    height: 15rem;
        margin: 0 0 4vw;
		    display: block;
		    position: relative;
		    background: url(../img/service.png) center center/42rem auto no-repeat;
				@include tab {
					content: "";
			    width: 48rem;
			    height: 11rem;
	        margin: 0 0 3rem;
			    background: url(../img/service.png) center center/100% auto no-repeat;
				};
				@include ssp {
					width: 80vw;
			    height: 8rem;
			    margin: 0 0 10vw;
			    background: url(../img/service.png) center center/80vw auto no-repeat;
				};
			}
		}
		p {
			margin-bottom: 1vw;
			font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
			@include tab {
		    margin-bottom: 3vw;
				font-size: 16px;
				font-size: 1.6rem;
			};
			@include ssp {
				width: 100%;
		    margin-bottom: 5vw;
				font-size: 3.8vw;
				line-height: 7.5vw;
			};
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	.circle_d {
		width: 16.4vw;
		height: 16.4vw;
		position: absolute;
		top: 8vw;
		right: 0;
	}
	.service_img_box {
		position: relative;
		.service_img {
			width: 40vw;
	    height: 30vw;
	    display: block;
	    background: url(../img/image_1.jpg) center center/cover no-repeat;
	    position: absolute;
	    top: -14.8vw;
	    right: 0;
	    border: 1px solid;
	    border-right: none;
	    z-index: 0;
			@include ssp {
				width: 85vw;
				height: 85vw;
        top: -160vw;
			};
			&::before {
				content: "";
				width: 102%;
				height: 1vw;
				position: absolute;
				top: -2vw;
				right: 0;
				border-bottom: 1px solid #000;
				display: block;
				@include ssp {
					width: 70%;
					top: -4vw;
				};
			}
		}
	}
}
.service_info_area {
	margin-bottom: 8vw;
	position: relative;
	&:after {
		content: "";
    width: 50%;
    height: 1vw;
    position: absolute;
		bottom: -6vw;
    display: block;
    border-bottom: 1px solid #000;
		@include tab {
			display: none;
		};
		@include ssp {
			width: 40vw;
	    bottom: -8vw;
		};
	}
	.circle_e {
		position: absolute;
		top: 10vw;
		left: 0;
	}
	.info_box_container {
		.info_box {
			width: 100rem;
	    margin: 0 auto;
	    display: flex;
      justify-content: center;
			@include tab {
		    width: 100%;
				display: flex;
				justify-content: space-evenly;
			};
			@include ssp {
				width: 100%;
    		display: block;
			};
			&::before {
				content: "";
				width: 42rem;
				height: 1rem;
				display: block;
				position: absolute;
				top: 21.5rem;
				right: 0;
				border-top: 1px solid #000;
				@include tab {
					width: 43vw;
					right: 0;
					left: unset;
				};
				@include ssp {
					content: "";
					width: 30.5rem;
					top: 56rem;
				};
			}
			li {
        width: 36rem;
				border: 1px solid ;
				text-align: center;
				@include tab {
			    width: 40%;
				};
				@include ssp {
					width: 100%;
  			  display: block;
				};
				&:first-of-type {
					margin-right: 10rem;
					@include tab {
						margin-right: 0;
					};
					@include ssp {
						width: 90%;
						margin: 15vw auto;
						&:after {
							content: "";
							width: 80vw;
							height: 1vw;
							display: block;
							position: absolute;
					    top: 35vw;
							left: 18vw;
							border-top: 1px solid #000;
						}
					};
				}
				&:last-of-type {
					div {
						h4 {
							position: relative;
							&:before {
								content: "";
						    width: 42rem;
						    display: block;
						    position: absolute;
                top: -3.5rem;
						    border-top: 1px solid #000;
								@include tab {
							    width: 41vw;
								};
								@include ssp {
									border-top: none;
								};
							}
							small{
								font-size: 14px;
							}
							@include ssp {
								&:before {
									width: 92vw;
								}
								&:after {
									width: 80vw;
							    top: -5vw;
							    left: 13vw;
								}
							};
						}
					}
					@include ssp {
						width: 90%;
						margin: 0 auto 15vw;
					};
				}
				span {
			    width: 90%;
	      	padding: 10rem 0;
			    margin: 0 auto;
			    display: block;
			    background: url(../img/icon.png) 8rem -16rem/50rem auto no-repeat;
			    border-bottom: 1px solid;
					@include tab {
						width: 100%;
						padding: 12.5vw 0;
						border-bottom: none;
				    background: url(../img/icon.png) 9vw -24vw/70vw auto no-repeat;
					};
					@include ssp {
						width: 30vw;
						padding: 10vw;
						margin: 10vw auto;
						background: url(../img/icon.png) 0 -40vw/93vw auto no-repeat;
						border: none;
					};
				}
				.service_A {
					&:before{
						content: "";
						display: block;
						-webkit-transform: skewX(150deg);
						-moz-transform: skewX(150deg);
						position: absolute;
						transform: skewY(145deg);
						border: 0.3px solid #000;
						width: 6rem;
				    top: 6rem;
            left: 6rem;
						@include tab {
							width: 8vw;
							top: 6vw;
							left: 3vw;
						};
						@include ssp {
							width: 12vw;
							top: 10vw;
							left: 2vw;
						};
					}
				}
				.service_B {
					@include ssp {
						position: relative;
					};
					&:before{
						content: "";
						display: block;
						-webkit-transform: skewX(150deg);
						-moz-transform: skewX(150deg);
						position: absolute;
						width: 6rem;
				    top: 6rem;
		        left: 51.5rem;
						transform: skewY(145deg);
						border: 0.3px solid #000;
						@include tab {
							width: 8vw;
							top: 6vw;
							left: 50vw;
						};
						@include ssp {
							width: 12vw;
							top: 0;
					    left: -33vw;
						};
					}
				}
				&:last-of-type {
					span {
				    width: 100%;
		        padding: 10rem 0;
				    margin: 0 auto;
				    display: block;
		        background: url(../img/icon.png) -22rem -16rem/50rem auto no-repeat;
				    border-bottom: none;
						@include  tab {
							width: 100%;
							padding: 12.5vw 0;
							background: url(../img/icon.png) -35vw -24vw/70vw auto no-repeat;
						};
						@include ssp {
							width: 30vw;
					    padding: 10vw;
					    margin: 10vw auto;
					    background: url(../img/icon.png) -57vw -39vw/90vw auto no-repeat;
						};
					}
				}
				div {
					h4 {
            margin: 4rem 0 3rem;
						font-size: 16px;
						font-size: 1.6rem;
						font-weight: bold;
						@include ssp {
							margin: 10vw 0;
						};
						small {
					    font-weight: normal;
							color: #309283;
						}
					}
					h4.title_text_A {
						&::before {
							@include tab {
								content:"";
								width: 38vw;
						    height: 1vw;
						    display: block;
						    position: absolute;
						    top: 25vw;
						    left: 65px;
								border-top: 1px solid #000;
							};
						}
					}
					p {
						padding: 2rem;
						padding-top: 0;
						text-align: left;
						font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
					}
				}
			}
		}
	}
}


/* ------------------------------------------------------------
	main_media
------------------------------------------------------------ */

.media_container {
	background: #fff;
	.media_text_box {
    padding: 5vw 0 17vw;
		@include tab {
			margin-top: -10vw;
			padding: 10vw 0 15vw;
		};
		@include ssp {
			padding: 5vw 0 25vw;
		};
		h2 {
			margin-top: -100px;
			padding: 160px 0 9vw;
			text-align: center;
			font-size: 33px;
			font-size: 3.3rem;
			@include tab {
				margin-top: 5vw;
				padding: 0 0 5vw;
    		font-size: 5vw;
			};
			@include ssp {
				margin-top: 20vw;
				padding: 0 0 5vw;
				font-size: 24px;
		    font-size: 2.4rem;
			};
			small {
				margin-bottom: -6rem;
				font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
				display: block;
				font-size: 11px;
				@include tab {
					margin-bottom: -8vw;
					font-size: 2vw;
				};
				@include  ssp {
					font-size: 10px;
					font-size: 1rem;
					margin-bottom: -11vw;
				};
			}
		}
		.media_content {
			position: relative;
			.line_c {
				width: 12vw;
				height: 12vw;
				position: absolute;
				top: -21vw;
				right: 25vw;
		    z-index: 1;
				@include tab {
					width: 12vw;
			    height: 25vw;
			    top: -37vw;
					right: 7vw;
				};
				@include ssp {
					width: 25vw;
					height: 32vw;
			    top: -40vw;
					right: 0;
				};
			}
			.circle_g {
				width: 26rem;
				height: 26rem;
				position: absolute;
				top: -5vw;
        left: 67.5vw;
				z-index: 0;
				@include tab {
			    top: -11vw;
					right: 0;
					left: unset;
				};
				@include ssp {
					width: 50vw;
					height: 50vw;
			    top: 10vw;
					right: 0;
					left: unset;
				};
			}
			.media_img_box {
		    position: relative;
				.circle_f {
					width: 26rem;
					height: 26rem;
					position: absolute;
					top: 22vw;
					left: 0;
					z-index: 2;
					@include tab {
						width: 27rem;
						height: 29rem;
						top: -16vw;
					};
					@include ssp {
						width: 27vw;
						height: 40vw;
				    top: 76vw;
						left: 0;
					};
				}
				.media_img {
					width: 50vw;
					height: 35vw;
			    display: block;
			    background: url(../img/image_2.jpg) center center/cover no-repeat;
			    position: absolute;
			    top: -1vw;
			    left: 0;
			    border: 1px solid;
			    border-right: none;
					@include tab {
				    width: 85vw;
						height: 60vw;
						margin: 0 auto;
						background: url(../img/image_2.jpg) center center/cover no-repeat;
						position: relative;
						z-index: 1;
					};
					@include ssp {
						width: 94%;
						height: 66vw;
						top: 40vw;
						background: url(../img/image_2.jpg) center center/ cover no-repeat;
				    z-index: 1;
					};
					&::before {
						content: "";
				    width: 85%;
				    position: absolute;
				    top: -1.8vw;
				    left: 0;
				    border-top: 1px solid #000;
						@include tab {
							display: none;
						};
						@include ssp {
							border-top: none;
						};
					}
					&::after {
						content: "";
						width: 92%;
						position: absolute;
						top: -1vw;
						left: 0;
						border-top: 1px solid #000;
						@include tab {
							display: none;
						};
						@include ssp {
							border-top: none;
						};
					}
				}
			}
			.media_text {
				width: 45%;
		    margin: 0 0 0 auto;
				position: relative;
				@include tab {
					width: 85%;
					margin: 0 auto;
					position: relative;
				};
				@include ssp {
					width: 90%;
					margin: 0 auto;
				};
				&::before {
					content: "";
			    height: 115%;
			    position: absolute;
			    left: 42rem;
			    bottom: -20vw;
			    border-right: 1px solid #000;
					@include tab {
						height: 55vw;
				    top: -58vw;
						right: -2vw;
						left: unset;
					};
					@include ssp {
						height: 60vw;
						top: 38vw;
				    right: -1vw;
						left: unset;
					};
				}
				&::after {
					content: "";
					width: 35%;
					position: absolute;
			    left: 0;
			    bottom: -10vw;
			    border-bottom: 1px solid #000;
					@include tab {
						display: none;
					};
					@include ssp {
						display: none;
					};
				}
				h3 {
					span.media_c {
						content: "";
				    width: 34rem;
				    height: 15rem;
				    margin-bottom: 5rem;
				    display: block;
				    position: relative;
				    background: url(../img/media.png) center center/34rem auto no-repeat;
						@include tab {
							margin: 5vw 0 3vw auto;
							left: 0;
							width: 48vw;
							height: 18vw;
							background: url(../img/media_tab.png) center center/ 100% auto no-repeat;
						};
						@include ssp {
							content: "";
							width: 65vw;
							margin-bottom: 80vw;
							background: url(../img/media.png) center center/65vw auto no-repeat;
						};
					}
				}
				p {
					margin-bottom: 20px;
					font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
					@include tab {
						font-size: 16px;
						font-size: 1.6rem;
					};
					@include ssp {
						font-size: 3.8vw;
						line-height: 7.5vw;
					};
					@include ssp {
						&:nth-child(3) {
					    margin-bottom: 15vw;
						}
					};
					&:nth-child(3) {
				    margin-bottom: 7rem;
						@include ssp {
							margin-bottom: 12vw;
						};
					}
				};
				a {
					width: 28rem;
			    height: 7rem;
			    position: relative;
			    display: block;
			    background: #000;
			    line-height: 7rem;
			    color: #fff;
			    text-decoration: none;
			    text-align: center;
			    z-index: 10;
			    font-size: 1.8rem;
			    font-size: 18px;
					@include tab {
						width: 28rem;
						height: 7rem;
						position: static;
						margin: 0 auto;
						line-height: 7rem;
					};
					@include ssp {
						width: 80%;
						margin: 0 auto;
					};
					span {
						width: 1rem;
				    height: 2rem;
			  	  padding: 1rem;
            margin: -4.6rem 0 0 23rem;
				    position: relative;
				    display: block;
				    background: url(../img/icon.png) -33rem -5rem/41.5rem auto no-repeat;
						@include tab {
					    margin: -6.2vw 0 0 29vw;
						};
						@include ssp {
							margin: -4.6rem 0 0 60vw;
						};
					}
				}
				a.pc:before {
					content: "";
					position: absolute;
					display: block;
					background: #fff;
					width: 0;
					top: 0;
					bottom: 0;
					-webkit-transition: .4s ease 0s;
					transition: .4s ease 0s;
					z-index: -1;
					border: solid 1px #000;
				}
				a.pc:hover {
					color: #000;
					span {
				    margin: -4.6rem 0 0 23rem;
						background: url(../img/icon.png) -33rem -8.3rem/41.5rem auto no-repeat;
						color: #000;
						@include tab {
					    margin: -6.2vw 0 0 29vw;
						};
					}
				}
				a.pc:hover:before {
					width: 100%;
				}
			}
		}
	}
}


/* ------------------------------------------------------------
	main_company
------------------------------------------------------------ */
.company_info_box {
	opacity: 1;
	transform: translate(0, 0);
	@include tab {
		transform: none;
	};
}
.company_container {
	padding-top: 10px;
	margin-top: -10px;
	position: relative;
	h2 {
    margin: 30vw 0 14vw;
		font-size: 24px;
		font-size: 2.4rem;
		text-align: center;
		@include tab {
	    margin: 20vw 0 14vw;
		};
		@include tab {
			font-size: 5vw;
			small {
		    font-size: 2vw;
			}
		};
		small {
			margin-bottom: -11vw;
			font-size: 10px;
			font-size: 1rem;
			display: block;
			@include tab {
		    margin-bottom: -8vw;
				font-size: 2vw;
			};
		}
	}
	.circle_h {
		width: 26rem;
		height: 26rem;
		position: absolute;
		top: 3vw;
		left: 7vw;
		@include tab {
			top: 32vw;
			left: 4vw;
		};
		@include ssp {
			width: 50vw;
			height: 50vw;
			top: 44vw;
			left: 0;
		};
	}
	.circle_j {
		position: absolute;
		@include tab {
			width: 35vw;
			height: 35vw;
			z-index: 1;
			right: 0;
      bottom: 25vw;
		};
		@include ssp {
			width: 40vw;
			height: 40vw;
	    top: 94vw;
	    right: 0;
		};
	}
	.line_d {
		width: 12vw;
    height: 10vw;
    position: absolute;
    top: -7vw;
    left: 24vw;
    z-index: 1;
		@include tab {
			width: 26vw;
			height: 35vw;
			top: -12vw;
			left: 6vw;
		};
		@include ssp {
			width: 40vw;
			height: 30vw;
			top: -2vw;
			left: 5vw;
		};
	}
	.line_e {
		position: absolute;
	  top: 11vw;
		right: 24vw;
		z-index: 1;
		@include tab {
			top: 0;
			right: 20vw;
		};
		@include ssp {
			width: 7vw;
	    height: 8vw;
			top: -2vw;
	    right: 13vw;
		};
	}
	.line_f {
		position: absolute;
		@include tab {
			width: 15vw;
			height: 20vw;
	    bottom: -7vw;
		};
		@include ssp {
			width: 25vw;
			height: 25vw;
			top: 107vw;
	    right: 8vw;
		};
	}
	.company_info_container {
		position: relative;
		table {
			width: 49.3rem;
	    padding: 3.8rem 3rem;
			position: absolute;
			top: -1.7vw;
			left: 12.5vw;
			display: block;
			background: #fff;
			z-index: 1;
			@include tab {
				width: 85vw;
      	padding: 6vw 15vw;
        top: -38rem;
		    right: 0px;
		    left: unset;
				font-size: 16px;
				font-size: 1.6rem;
		    font-weight: bold;
			};
			@include ssp {
				width: 90%;
				margin-left: auto;
		    padding: 5vw;
				position: static;
			};
			thead {
				tr {
					th {
						margin: 0;
		        padding: 17px 22px 0;
						font-size: 3.3rem;
						font-size: 33px;
						font-family: "futura-pt", sans-serif;
				    font-weight: 500;
						small {
							margin: 2rem 0 -5.5rem 0;
							display: block;
							font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
							font-size: 14px;
							text-align: left;
						}
					}
				}
			}
			tbody {
		    padding: 2.5rem 3.5rem 2.5rem 4.6rem;
				display: block;
				@include tab {
			    padding: 0;
				};
				@include ssp {
					padding: 0;
				};
				tr {
					display: flex;
					position: relative;
	        padding: 2.5rem 0.5rem 1rem 0;
					@include ssp {
						width: 80%;
						margin: 5vw auto;
				    padding: 0;
						flex-wrap: wrap;
						border-bottom: 1px solid #000;
					};
					&:first-of-type {
		        padding: 3.2rem 0.25rem 1rem 0;
						@include ssp {
							padding: 0;
						};
						&::before {
							content: "";
							width: 116%;
							display: block;
							position: absolute;
							top: 0.25rem;
							left: -8vw;
							border-top: 1px solid #000;
							@include tab {
								display: none;
							};
							@include ssp {
								display: none;
							};
						}
					}
					&:nth-child(n+2):nth-child(-n+5) {
						&::before {
							content:"";
							display: block;
							position: absolute;
							left: 0;
							border-top: 1px solid #000;
							@include ssp {
								display: none;
							};
						}
					}
					&:nth-child(2) {
						&::before {
			        width: 94%;
					    top: 0.5rem;
							@include tab {
								width: 100%;
							};
						}
					}
					&:nth-child(3) {
		        padding: 2.5rem 0.25rem 0 0;
						@include ssp {
							padding: 0;
						};
						&::before {
							width: 100%;
							top: 0;
						}
						td {
							&:last-of-type {
						    margin-top: -0.8rem;
					  	  line-height: 2.8rem;
							}
						}
					}
					&:nth-child(4) {
						padding: 3.5rem 0.5rem 1rem 0.5rem;
						@include ssp {
							padding: 0;
						};
						&::before {
							width: 100%;
					    top: 0.8rem;
							@include tab {
								width: 100%;
							};
							@include ssp {
								display: none;
							};
						}
					}
					&:nth-child(5) {
						@include ssp {
							border-bottom: none;
						};
						&::before {
							width: 105%;
					    top: 0.5rem;
							@include tab {
								width: 100%;
							};
						}
						td {
							&:last-of-type {
						    margin-top: -0.5rem;
							}
						}
					}
					&:last-of-type {
						position: relative;
						&::after {
							content: "";
							width: 40rem;
							height: 1rem;
							display: block;
							position: absolute;
							bottom: 0;
							left: 0;
							border-bottom: 1px solid #000;
							@include tab {
								display: none;
							};
							@include ssp {
								width: 20%;
								display: none;
							};
						}
					}
					td {
						width: 8rem;
						text-align: left;
						@include tab {
			        width: 30%;
						};
						@include ssp {
							padding: 0;
							&:first-of-type {
								font-weight: bold;
							}
						};
						&:nth-child(2){
							width: 22rem;
							margin-left: auto;
							@include tab {
						    width: 75%;
						    font-weight: normal;
							};
							@include ssp {
								margin-left: 0;
							};
						}
					}
				}
			}
		}
	}
	.google_map {
		width: 100%;
    height: 68rem;
		margin: 12.3vw 0 10vw;
		overflow: hidden;
		border:0;
		@include tab {
	    height: 60vw;
      margin: 64vw 0 7vw;
		};
		@include ssp {
			height: 150vw;
			margin: -17vw 0 0;
			position: relative;
			z-index: -1;
		};
		&::before {
			content: "";
			width: 80%;
			height: 1vw;
			position: absolute;
	    top: -0.7vw;
			right: 0;
			display: block;
			border-top: 1px solid #000;
			@include tab {
				width: 100%;
        top: 100vw;
			};
			@include ssp {
				width: 95%;
				height: 1vw;
				top: 4vw;
			};
		}
		&::after {
			content: "";
      width: 73.2%;
			height: 1vw;
			position: absolute;
			bottom: -0.8vw;
			right: 0;
			z-index: 0;
			display: block;
			border-bottom: 1px solid #000;
			@include tab {
				width: 100%;
        top: 158vw;
		    bottom: unset;
			};
			@include ssp {
				width: 95%;
				height: 1vw;
				bottom: 1vw;
			};
		}
		iframe {
			width: 72.5%;
	    height: 100rem;
			margin: -15rem 0 0 auto;
			display: block;
			@include tab {
				width: 100%;
		    height: 110vw;
			};
			@include ssp {
				width: 100%;
				height: 240vw;
				margin-top: -150px;
				border: 0;
			};
		}
	}
	span {
		&::after {
			content: "";
			height: 20vw;
			position: absolute;
	    bottom: -0.8vw;
      left: 26.55vw;
			z-index: 0;
			display: block;
			border-left: 1px solid #000;
			@include tab {
				display: none;
			};
			@include ssp {
				height: 145vw;
		    bottom: 1.2vw;
				left: 5vw;
			};
		}
	}
}


/* ------------------------------------------------------------
	main_contact
------------------------------------------------------------ */

.contact_container {
	width: 100%;
	margin: 0 auto;
  padding: 10rem 0;
	background: #fff;
	position: relative;
	@include tab {
    padding: 10rem 0 15rem;
	};
	@include ssp {
		padding: 15vw 0 24vw;
		&::before {
			content: "";
			width: 90%;
			height: 1vw;
			position: absolute;
			top: 5vw;
			right: 0;
			z-index: 0;
			display: block;
			border-bottom: 1px solid #000;
		}
	};
	.contact {
    width: 100%;
		margin: 0 auto;
		@include tab {
			width: 80%;
			margin: 0 auto;
		};
		@include ssp {
			width: 70%;
		};
		.contact_title {
	    width: 75rem;
			margin: 0 auto;
			font-family :"A1 Gothic R JIS2004";
			@include tab {
				width: 100%;
			};
			@include ssp {
				width: 100%;
		    margin: 20vw 0 15vw;
			};
			h2 {
				margin: 4vw 0 3vw auto;
				font-size: 3.3rem;
				font-size: 33px;
				display: inline;
				border-bottom: 1px solid ;
				@include tab {
					border-bottom: none;
					font-size: 5vw;
				};
				@include ssp {
					font-size: 24px;
					font-size: 2.4rem;
				};
				small {
					margin-bottom: -5rem;
					font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
					display: block;
			    font-size: 14px;
					@include tab {
				    margin-bottom: -8vw;
					};
					@include ssp {
						margin-bottom: -11vw;
						font-size: 10px;
						font-size: 1rem;
					};
				}
			}
		}
		.mail_box {
	    width: 75rem;
			margin: 0 auto;
	    margin-top: 5rem;
			display: flex;
			justify-content: space-between;
			@include tab {
				width: 60%;
		    margin-left: 15rem;
		    display: block;
			};
			@include ssp {
				width: 100%;
				display: block;
			};
			p {
		   	margin-right: 2vw;
				font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
		    font-size: 2rem;
				@include tab {
					width: 100%;
					margin: 10vw 0 5vw;
					font-size: 1.8rem;
				};
				@include ssp {
					margin-right: 0;
					font-size: 3.8vw;
					line-height: 7.5vw;
			    letter-spacing: 0;
				};
			}
			a {
				width: 28rem;
				height: 7rem;
				position: relative;
				display: block;
				background: #000;
				line-height: 7rem;
				color: #fff;
				text-decoration: none;
				text-align: center;
				z-index: 10;
				font-size: 1.8rem;
				font-size: 18px;
				@include tab {
		  	  width: 32rem;
				};
				@include ssp {
			    width: 100%;
			    margin: 10vw auto 5vw;
				};
				span {
					width: 1rem;
			    height: 2rem;
          margin: -4.6rem 0 0 22rem;
					padding: 1rem;
			    position: relative;
			    display: block;
			    background: url(../img/icon.png) -33rem -5rem/41.5rem auto no-repeat;
					@include tab {
				    margin: -4.6rem 0 0 26rem;
					};
					@include ssp {
						margin: -4.6rem 0 0 60vw;
					};
				}
			}
			a.pc:before {
				content: "";
		    position: absolute;
		    display: block;
		    background: #fff;
		    width: 0;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    -webkit-transition: .4s ease 0s;
		    transition: .4s ease 0s;
		    z-index: -1;
		    border: solid 1px #000;
			}
			a.pc:hover {
				margin: 0;
				color: #000;
				span {
					margin: -4.6rem 0 0 22rem;
					background: url(../img/icon.png) -33rem -8.3rem/41.5rem auto no-repeat;
					color: #000;
				}
			}
			a.pc:hover:before {
				@include ssp {
					display: none;
				};
				width: 100%;

			}
		}
	}
}

/* ------------------------------------------------------------
	2.contact_form
------------------------------------------------------------ */

.contact_form_container {
	width: 100%;
	margin: 0 auto;
  padding: 15rem 0 10rem;
	background: #fff;
	@include tab {
		padding: 20rem 0 10rem;
	};
	@include ssp {
    padding: 35vw 0 14vw;
	};
	.contact {
		width: 866px;
		margin: 0 auto;
		@include ssp {
			width: 100%;
		}
		@include tab {
			width: 90%;
		}
		.contact_title {
			margin-bottom: 5rem;
			font-family: "futura-pt", sans-serif;
			text-align: center;
			@include ssp{
				margin: 0 0 15vw;
			}
			h2 {
				margin: 4rem 0 3rem auto;
		    font-size: 33px;
				font-size: 3.3rem;
		    display: inline;
				@include tab {
					font-size: 33px;
					font-size: 3.3rem;
				};
				@include ssp {
					font-size: 30px;
					font-size: 3rem;
				};
				small {
			    margin-bottom: -5rem;
					font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
					display: block;
					font-size: 1.4rem;
					font-size: 14px;
				}
			}
		}
		.contact_form_box {
			.form_content {
				.form_corp,.form_name,.form_tell,.form_mail {
					display: flex;
					border-bottom: 1px solid #000;
					@include ssp {
						width: 90%;
						margin: 0 auto;
						margin-bottom: 4vw;
						display: block;
					}
					label {
						margin-bottom: 0;
						padding: 3rem 0;
						font-weight: bold;
						font-size: 1.8rem;
						font-size: 18px;
						@include ssp {
							width: 100%;
							text-align: left;
							margin: 0 auto;
							padding: 0;
							display: block;
						}
					}
				}
				input {
					width: 70%;
			    margin: 1rem 0 1rem auto;
			    padding: 1rem 2rem;
			    display: block;
			    background: #f2f2f2;
			    border: none;
					@include ssp {
						width: 100%;
				    margin: 2vw 0 4vw auto;
					}
				}
				.form_mail {
					@include ssp {
						margin-bottom: 0;
					};
				}
				.inquiry_box {
					@include ssp {
						width: 90%;
						margin: 0 auto;
						border-bottom: 1px solid #000;
					}
					label {
						margin: 0;
				    padding: 4rem 0;
						font-weight: bold;
						font-size: 18px;
						font-size: 1.8rem;
						@include ssp {
							padding: 4vw 0;
						};
					}
					textarea {
						width: 100%;
						height: 30rem;
						margin: 0 0 4rem auto;
						background: #f2f2f2;
						border: none;
						@include ssp {
							height: 25vw;
					    margin: 0 0 4vw auto;
						};
					}
				}
				input[type=text] {
				  font-size: 16px;
		      transform: scale(1);
				}
			}
		}
		.mail_box {
			@include ssp {
				width: 90%;
				margin: auto;
			};
			p {
				padding: 4rem 0;
				font-size: 1.6rem;
				font-size: 16px;
				font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
				text-align: center;
				@include ssp {
			    padding: 8vw 0;
					text-align: left;
					font-size: 4vw;
					font-weight: normal;
				};
				span {
					border-bottom: 1px solid #000;
					@include ssp {
						font-weight: normal;
					}
				}
			}
			.check_box_area {
				margin: auto;
				padding: 50px;
				position: relative;
				@include tab {
			    padding: 9vw 0;
				};
				@include ssp {
					margin: auto;
	        padding: 14vw 0 9vw;
					text-align: center;
				};
				input[type="checkbox"] { display: none; }
				input[type="checkbox"] + label {
			    width: 18px;
			    height: 18px;
			    position: absolute;
			    top: 7.9rem;
			    left: 29rem;
					@include tab {
				    left: 20.5rem;
					};
					@include ssp {
						margin-top: 1vw;
						top: 21.5vw;
						left: 10vw;
					};
				}
				input[type="checkbox"] + label:before {
					content: '';
					display: inline-block;
					width: 18px;
					height: 18px;
					border: 1px solid #000;
					position: absolute;
					top: 0;
					left: 0;
					-webkit-transition: all .12s, border-color .08s;
					transition: all .12s, border-color .08s;
					@include tab {
				    top: 2rem;
					};
					@include ssp {
						width: 5vw;
						height: 5vw;
        	  top: -1.2vw;
						left: 0;
					};
				}
				input[type="checkbox"]:checked + label:before {
					width: 10px;
					top: -2px;
					left: 5px;
					border-top-color: transparent;
					border-left-color: transparent;
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					@include tab {
				    top: 1.7rem;
					};
					@include ssp {
            top: -2vw;
						left: 1vw;
					};
				}
			}
			.send_button {
				width: 293px;
				height: 63px;
				margin: 0 auto;
				display: block;
				background: #000;
				font-weight: bold;
				line-height: 63px;
				position: relative;
				color: #fff;
				font-size: 18px;
				font-size: 1.8rem;
				text-decoration: none;
				text-align: center;
				z-index: 10;
				span {
					width: 10%;
					height: 10%;
					padding: 0.8vw 1vw;
					position: relative;
					display: block;
					background: url(../img/icon.png) -15vw -2.9vw/25vw auto no-repeat;
					top: 0.6vw;
					left: 0.2vw;
				}
			}
			.privacy_link {
				color: #000;
				text-decoration: none;
			}
			.hv_send_button:before {
				content: "";
				position: absolute;
				display: block;
				background: #fff;
				width: 0;
				top: 0;
				left: 0;
				bottom: 0;
				-webkit-transition: .4s ease 0s;
				transition: .4s ease 0s;
				z-index: -1;
				border: solid 1px #000;
			}
			.hv_send_button:hover {
				color: #000;
				span {
					color: #000;
					background: url(../img/icon.png) -15vw -4.8vw/25vw auto no-repeat;
				}
			}
			a.hv_send_button:hover:before {
				width: 100%;
			}
		}
	}
}

/* ------------------------------------------------------------
	3.contact_check
------------------------------------------------------------ */

.contact_confirm_container {
	width: 100%;
	margin: 0 auto;
  padding: 15rem 0 10rem;
	background: #fff;
	@include tab {
		padding: 20rem 0 10rem;
	};
	@include ssp {
		padding: 35vw 0 8vw;
	};
	.confirm {
		width: 866px;
		margin: 0 auto;
		@include ssp {
			width: 100%;
		}
		@include tab {
			width: 90%;
		}
		.contact_confirm_title {
			margin-bottom: 5rem;
			font-family: "futura-pt", sans-serif;
			text-align: center;
			@include ssp {
				margin: 0 0 15vw;
			}
			h2 {
				margin: 4rem 0 3rem auto;
		    font-size: 33px;
				font-size: 3.3rem;
		    display: inline;
				@include tab {
					font-size: 33px;
					font-size: 3.3rem;
				};
				@include ssp {
					font-size: 30px;
					font-size: 3rem;
				};
				small {
			    margin-bottom: -5rem;
					font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
					display: block;
					font-size: 1.4rem;
					font-size: 14px;
				}
			}
		}
		.confirm_text {
			margin-bottom: 8rem;
			text-align: center;
			font-size: 16px;
			font-size: 1.6rem;
			@include ssp {
				width: 90%;
				margin: 0 auto 10vw;
				font-size: 1.4rem;
				text-align: left;
				letter-spacing: 0.56px;
			};
		}
		.contact_confirm_box {
			table {
				width: 100%;
				tbody{
			    border: 1px solid #000;
					border-right: none;
					border-left: none;
					@include ssp {
						border: none;
					};
					tr {
						&:last-of-type {
							th,td {
								line-height: 2;
								padding: 2rem;
						    font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
						    text-indent: 0;
								border-bottom: none;
							}
							td {
								height: 100%;
								padding: 10px;
								letter-spacing: 0.56px;
								font-size: 15px;
								font-size: 1.5rem;
							}
						}
						th,td {
							font-size: 18px;
							font-size: 1.8rem;
							@include ssp {
								margin: 0 auto;
								display: block;
							};
						}
						th {
							width: 25rem;
					    line-height: 7rem;
					    text-align: center;
					    background: #f2f2f2;
							font-weight: bold;
					    border-bottom: 1px solid #ccc;
							@include ssp {
								width: 90%;
								text-align: left;
								text-indent: 2rem;
								border-bottom: none;

							};
						}
						td {
					    text-indent: 2rem;
							border-bottom: 1px solid #ccc;
							@include ssp {
								width: 90%;
								height: 7rem;
						    line-height: 7rem;
								border-bottom: none;
								color: #000;
							};
						}
					}
				}
			}
		}
		.mail_box {
			width: 55rem;
			margin: 5rem auto 0;
			display: flex;
			@include ssp {
				width: 100%;
        margin: 10vw auto;
				flex-wrap: wrap-reverse;
			};
			p {
		    padding: 4rem 0;
				font-size: 1.6rem;
				font-size: 16px;
				font-weight: bold;
				font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
				text-align: center;
				@include ssp {
			    padding: 8vw 0;
					text-align: left;
					font-size: 4vw;
					font-weight: normal;
				};
				span {
					border-bottom: 1px solid #000;
					@include ssp {
						font-weight: normal;
					}
				}
			}
			.return_button {
		    width: 235px;
		    height: 63px;
		    margin: 0 auto;
		    display: block;
		    background: #b3b3b3;
		    line-height: 63px;
		    position: relative;
		    color: #fff;
				font-size: 18px;
				font-size: 1.8rem;
		    font-weight: bold;
		    text-decoration: none;
		    text-align: center;
				z-index: 10;
				@include ssp {
					width: 70%;
			    margin-top: 4vw;
				};
			}
			.hv_return_button::before {
				content: "";
				position: absolute;
				display: block;
				background: #fff;
				width: 0;
				top: 0;
				left: 0;
				bottom: 0;
				-webkit-transition: .4s ease 0s;
				transition: .4s ease 0s;
				z-index: -1;
				border: solid 1px #b3b3b3;
			}
			.hv_return_button:hover {
				color: #b3b3b3;
				span {
					color: #b3b3b3;
					background: url(../img/icon.png) -15vw -4.8vw/25vw auto no-repeat;
				}
			}
			.send_button {
		    width: 235px;
		    height: 63px;
		    margin: 0 auto;
		    display: block;
		    background: #000;
		    line-height: 63px;
		    position: relative;
		    color: #fff;
				font-size: 18px;
				font-size: 1.8rem;
		    font-weight: bold;
		    text-decoration: none;
		    text-align: center;
				z-index: 10;
				@include ssp {
					width: 70%;
					margin-top: 2.5vw;
				};
			}
			.hv_send_button::before {
				content: "";
				position: absolute;
				display: block;
				background: #fff;
				width: 0;
				top: 0;
				left: 0;
				bottom: 0;
				-webkit-transition: .4s ease 0s;
				transition: .4s ease 0s;
				z-index: -1;
				border: solid 1px #000;
			}
			.hv_send_button:hover {
				color: #000;
				span {
					color: #000;
					background: url(../img/icon.png) -15vw -4.8vw/25vw auto no-repeat;
				}
			}
			a.return_button:hover:before,
			a.hv_send_button:hover:before {
				width: 100%;
			}
		}
	}
}

/* ------------------------------------------------------------
	4.media
------------------------------------------------------------ */

.media_page_container {
	width: 100%;
	margin: 0 auto;
  padding: 15rem 0 10rem;
	background: #fff;
	@include tab {
	  padding: 20rem 0 10rem;
	};
	@include ssp {
    padding: 35vw 0 17vw;
	};
	.media_box {
		text-align: center;
		.media_title {
      margin-bottom: 8rem;
			h2 {
				margin: 4vw 0 3vw auto;
				font-size: 33px;
				font-size: 3.3rem;
				display: inline;
				@include tab {
					font-size: 33px;
					font-size: 3.3rem;
				};
				@include ssp {
					font-size: 30px;
					font-size: 3rem;
				};
				small {
					margin-bottom: -5rem;
					display: block;
					font-size: 1.4rem;
					font-size: 14px;
					@include tab {
						margin-bottom: -5rem;
						font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
						display: block;
						font-size: 1.4rem;
						font-size: 14px;
					};
					@include ssp {
						margin-bottom: -13vw;
						font-size: 3vw;
					};
				}
				&::after {
					content: "";
			    width: 3rem;
					margin: 0 auto;
			    position: relative;
			    top: 2rem;
			    display: block;
			    border-bottom: 1px solid #000;
					@include ssp {
						top: 9vw;
					};
				}
			}
		}
		p {
	    margin-bottom: 10rem;
			font-size: 30px;
			font-size: 3.3rem;
	    font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
	    font-weight: bold;
	    line-height: 1.7;
			@include ssp {
				margin-bottom: 15vw;
		    font-size: 5.5vw;
			};
		}
	}
	.circle_box {
    position: relative;
		.circle_a {
			width: 26rem;
			height: 26rem;
			position: absolute;
			top: -5rem;
			right: 6rem;
	    z-index: 2;
			@include tab {
		    top: -7rem;
		    right: 0;
			};
			@include ssp {
				width: 45vw;
				height: 45vw;
				top: -20vw;
				right: 0;
			};
		}
	}
	.media_img {
		width: 73%;
    height: 39vw;
    margin: 0 auto;
    display: block;
		position: relative;
    background: url(../img/image_3.jpg) center center/cover no-repeat;
		z-index: 1;
		@include tab {
			width: 90%;
			height: 48vw;
		};
		@include ssp {
			width: 100%;
	    height: 77vw;
	    margin-bottom: 10rem;
			background: url(../img/media_sp.jpg) center center/cover no-repeat;
		};
	}
	.circle_box {
		position: relative;
		.circle_b {
			width: 39rem;
			height: 39rem;
			position: absolute;
			top: -8vw;
			left: 0;
			z-index: 0;
			@include tab {
		    top: -9vw;
			};
			@include ssp {
				width: 61%;
				top: -61vw;
			};
		}
	}
	.media_text_area {
		width: 65%;
    margin: 12rem auto 0;
		display: flex;
		@include tab {
	    width: 80%;
	    margin: 0 auto;
			display: block;
		};
		@include ssp {
			width: 100%;
			margin: 0;
			display: block;
		};
		.text_box_L {
			width: 45rem;
			@include ssp {
				width: 100%;
			};
			h2 {
				margin: 0;
				padding: 0;
				@include ssp {
					width: 90%;
					margin: 0 auto 10vw;
				};
				.media_title {
					content: "";
	        width: 45rem;
			    height: 13rem;
			    margin: 0 auto;
			    display: block;
					position: relative;
			    background: url(../img/media_text.png) center center/45rem auto no-repeat;
					z-index: 1;
					@include pc {
						content: "";
						width: 30vw;
						height: 9vw;
						background: url(../img/media_text.png) center center/28vw auto no-repeat;
					};
					@include tab {
						margin: 10vw auto 6vw;
					};
					@include ssp {
						width: 90%;
				    height: 30vw;
				    margin: 0 auto 0 0;
				    background: url(../img/media_text.png) center center/100% auto no-repeat;
					};
				}
			}
		}
		.line_box {
			position: relative;
			.line_a {
				width: 57vw;
		    height: 93vw;
		    position: absolute;
				top: -42vw;
		    left: -15rem;
		    z-index: 0;
				@include ssp {
					width: 25vw;
			    height: 30vw;
			    top: -60vw;
			    right: 0;
			    left: unset;
				};
			}
		}
		.text_box_R {
			width: 55rem;
      margin: 0 auto;
	    padding: 5rem 5rem 0;
	    display: block;
	    position: relative;
	    top: -6rem;
			background: #fff;
	    z-index: 1;
			@include tab {
				width: 100%;
				margin: 0;
				padding: 0;
				position: relative;
				top: 0;
				background: none;
				&::after {
					content: "";
					width: 100%;
					height: 10vw;
					display: block;
					background: #fff;
					position: absolute;
					top: 13.5vw;
					left: 0vw;
					z-index: 0;
					transform: rotate(-42deg);
				}
			};
			@include ssp {
				width: 100%;
				position: unset;
				padding: 0;
				margin: 0;
			};
			p {
		    width: 49rem;
		    margin-bottom: 3rem;
        line-height: 2.2;
				font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
				@include tab {
			    width: 100%;
			    margin-bottom: 2rem;
			    letter-spacing: 0;
					position: relative;
					z-index: 1;
				};
				@include ssp {
					width: 90%;
			    margin: 0 auto 8vw;
					font-size: 3.8vw;
					line-height: 7.5vw;
				};
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
	.line_box {
		position: relative;
		.line_a {
			position: absolute;
			width: 98%;
			height: 100vw;
			top: -79vw;
			right: 0;
		}
	}
}
.service_model {
  padding: 13rem 0 9.5rem;
	@include ssp {
		padding: 35vw 0 17vw;
	};
	.line_box {
		position: relative;
		.line_b {
			height: 20vw;
	    position: absolute;
	    top: -42vw;
		}
	}
	.service_model_box {
		text-align: center;
    overflow: hidden;
		.service_model_title {
      margin-bottom: 15rem;
			h2 {
				margin: 4vw 0 3vw auto;
		    font-size: 3rem;
				font-weight: bold;
				display: inline;
				font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
				@include ssp {
		    	font-size: 5.5vw;
				};
				small {
					margin-bottom: -3vw;
					display: block;
					font-weight: normal;
					font-size: 15px;
					font-family: "futura-pt", sans-serif;
				}
				&::after {
					content: "";
			    width: 110rem;
			    margin: 0 auto;
			    position: relative;
			    top: 11.5rem;
			    right: -10rem;
			    display: block;
			    border-bottom: 1px solid #000;
			    z-index: 1;
					@include tab {
				    top: 6rem;
					};
					@include ssp {
						content: "";
				    width: 90vw;
				    margin: 0 auto 0 0;
				    position: relative;
				    top: 6rem;
				    right: unset;
				    left: 0;
				    display: block;
				    border-bottom: 1px solid #000;
					};
				}
			}
		}
	}
	figure {
		margin: 0;
		padding: 0;
		@include ssp {
			margin: 0 0 14vw 0;
			padding: 0;
		};
		img {
	    width: 55rem;
			margin: 0 auto;
			height: auto;
			display: block;
			@include ssp {
				width: 90%;
				height: auto;
				display: block;
			};
		}
	}
	.service_model_list {
		@include ssp {
  	  font-size: 3.8vw;
		};
		ul {
			width: 90%;
			margin: 0 auto;
			padding-top: 5vw;
			border-top: 1px solid #000;
			li {
		    margin-bottom: 2vw;
				span {
					font-weight: bold;
				}
				&:last-of-type{
			    margin-bottom: 0;
					padding-left:1em;
					text-indent:-1em;
				}
			}
		}
	}
	.circle_box {
		position: relative;
		.circle_c {
			width: 26rem;
			height: 26rem;
	    position: absolute;
			top: -10rem;
			@include ssp {
				width: 45vw;
				height: 45vw;
				position: absolute;
		    top: -57vw;
				right: 0;
			};
		}
	}
}
.flow_model {
  padding: 15rem 0 6.5rem;
	background: #fff;
	@include ssp {
    padding: 40vw 0 20vw;
	};
	.line_box {
		position: relative;
		.line_c {
			position: absolute;
			height: 40vw;
			top: -50vw;
			right: 0;
		}
	}
	.line_box {
		position: relative;
		.line_d {
			position: absolute;
			height: 8vw;
			top: -5vw;
			left: 0;
		}
	}
	.flow_model_box {
		text-align: center;
		overflow: hidden;
		.flow_model_title {
      margin-bottom: 16rem;
			@include tab {
				margin-bottom: 15rem;
			};
			@include ssp {
		    margin-bottom: 12vw;
			};
			h2 {
				margin: 4vw 0 3vw auto;
		    font-size: 3rem;
				font-weight: bold;
				display: inline;
				font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
				@include ssp {
			    font-size: 5.5vw;
				};
				small {
					margin-bottom: -3vw;
					display: block;
					font-weight: normal;
					font-size: 15px;
					font-family: "futura-pt", sans-serif;
				}
				&::after {
					content: "";
					width: 110rem;
					margin: 0 auto;
					position: relative;
					top: 11.5rem;
			    left: -10rem;
					display: block;
					border-bottom: 1px solid #000;
					z-index: 1;
					@include tab {
				    top: 7.5rem;
					};
					@include ssp {
						content: "";
						width: 95vw;
						margin: 0 0 0 auto;
						position: relative;
						top: 12vw;
						left: unset;
					};
				}
			}
		}
	}
	.circle_box {
		position: relative;
		.circle_d {
			width: 39rem;
	    height: 39rem;
			position: absolute;
	    top: -23rem;
			right: 0;
			@include tab {
				width: 39rem;
				height: 39rem;
				position: absolute;
				top: -32rem;
				right: 0;
			};
			@include ssp {
				display: none;
			};
		}
	}
	.flow_category {
    width: 68rem;
		margin: 0 auto;
		position: relative;
		@include tab {
			width: 75%;
			margin-left: 15vw;
		};
		@include ssp {
			width: 100%;
		};
		ul {
	    margin-bottom: 0;
			li {
        margin: 0 auto;
				display:flex;
				@include ssp {
					width: 90%;
					margin: 0 auto;
			    padding: 15vw 4vw;
					display: flex;
					border-bottom: 1px solid #bfbfbf;
					&:last-of-type {
				    padding-bottom: 0;
						border-bottom: none;
					}
				};
				span {
					width: 50px;
			    height: 50px;
			    margin: 0;
					padding: 0;
			    line-height: 50px;
					position: relative;
			    text-align: center;
					font-size: 15px;
			    display: inline-block;
			    border-radius: 50%;
			    border: 1px solid;
					@include tab {
						width: 45px;
						height: 45px;
						line-height: 45px;
					};
					@include ssp {
						width: 15%;
						height: 11vw;
						margin-right: 5vw;
						line-height: 11vw;
					};
					&::after {
						content: "";
						width: 5rem;
						margin: 0 auto;
						position: absolute;
						top: 2.5rem;
						right: -5rem;
						border-bottom: 1px solid #000;
						@include tab {
					    top: 2rem;
						};
						@include ssp {
							display: none;
						};
					}
				}
				div {
			    margin: 1rem 0 4.85rem 8rem;
					@include ssp {
				    width: 90%;
						margin: 0;
					};
					h3 {
				    margin-bottom: 1.5rem;
						font-size: 20px;
						font-weight: bold;
						font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
						@include ssp {
					    margin: 2vw 0 4.5vw 0;
							font-size: 4.8vw;
						};
					}
					p {
				    line-height: 2;
						font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
						@include tab {
					    letter-spacing: -1px;
						};
						@include ssp {
			        font-size: 3.8vw;
					    line-height: 7.5vw;
						};
					}
				}
			}
		}
	}
}


/* ------------------------------------------------------------
	5.thanks_contact
------------------------------------------------------------ */

.thanks_container {
	width: 100%;
	margin: 0 auto;
	padding: 5vw 0;
	background: #fff;
	.contact {
		.contact_title {
			text-align: center;
			h2 {
				margin: 4vw 0 3vw auto;
		    font-size: 2vw;
				display: inline;
				small {
					margin-bottom: -3vw;
					display: block;
					font-size: 11px;
				}
			}
		}
		.mail_box {
			margin-top: 7vw;
			text-align: center;
			a {
				width: 17%;
		    margin: 0 auto 4.5vw;
		    height: 4vw;
		    position: relative;
		    display: block;
		    background: #000;
		    line-height: 4vw;
		    color: #fff;
		    text-decoration: none;
		    text-align: center;
		    z-index: 10;
			}
			p {
				margin-bottom: 5vw;
			}
			a.hov_btn:before {
				content: "";
		    position: absolute;
		    display: block;
		    background: #fff;
		    width: 0;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    -webkit-transition: .4s ease 0s;
		    transition: .4s ease 0s;
		    z-index: -1;
		    border: solid 1px #000;
			}
			a.hov_btn:hover {
				color: #000;
				span {
					color: #000;
					background: url(../img/icon.png) -19.8vw -4.9vw/25vw auto no-repeat;
				}
			}
			a.hov_btn:hover:before {
				width: 100%;
			}
		}
	}
}


/* ------------------------------------------------------------
	6.privacy_poricy
------------------------------------------------------------ */
#pp_header {
	background: #ebebeb;
}
.privacy_container {
	width: 100%;
	margin: 0 auto;
	padding: 15rem 0 10rem;
	background: #fff;
	@include tab {
		padding: 20rem 0 10rem;
	};
	@include ssp {
		padding: 35vw 0 8vw;
	};
	.privacy {
		width: 736px;
		margin: 0 auto;
		@include ssp {
			width: 90%;
			margin: 0 auto;
		};
		h3,h4,p,a,li {
			font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
		}
		.contact_title {
			@include ssp {
		    margin-bottom: 20vw;
			};
      margin-bottom: 10rem;
			text-align: center;
			font-family :"A1 Gothic R JIS2004";
			h2 {
				margin: 4vw 0 3vw auto;
				font-size: 33px;
				font-size: 3.3rem;
				display: inline;
				@include tab {
					font-size: 33px;
					font-size: 3.3rem;
				};
				@include ssp {
					font-size: 30px;
					font-size: 3rem;
				};
				small {
					margin-bottom: -5rem;
					display: block;
					font-size: 14px;
				}
			}
		}
		.vision {
			h3 {
		    margin-bottom: 0;
				font-size: 3.3rem;
		    font-size: 33px;
				border-bottom: 1px solid;
				@include ssp {
					font-size: 7.5vw;
					letter-spacing: -0.44px;
				};
			}
			p {
				padding: 5rem 0;
				font-size: 1.4rem;
				font-size: 14px;
				@include ssp {
			    padding: 10vw 0;
				};
				span {
					&:first-of-type {
						margin-bottom: 5vw;
						display: block;
					}
				}
			}
		}
		.personal_info {
	    padding: 0;
			font-size: 1.4rem;
	    font-size: 14px;
			h3 {
				margin-bottom: 0;
				font-size: 33px;
				font-size: 3.3rem;
				border-bottom: 1px solid;
				@include ssp {
					font-size: 7.5vw;
					letter-spacing: -0.44px;
				};
			}
			h4 {
		    margin: 0;
        font-size: 1.8rem;
				font-size: 18px;
				font-weight: bold;
				@include ssp {
					margin-top: 0;
					font-size: 4.5vw;
					text-indent: -4vw;
					margin-left: 5vw;
					letter-spacing: -0.44px;
				};
				&:first-of-type {
					margin-top: 5rem;
				}
			}
			ul {
				padding: 1rem 0 4rem;
				display: block;
				margin: 0 auto;
				@include ssp {
					padding: 2rem 0 4rem;
				};
				li {
					font-size: 1.4rem;
					font-size: 14px;
				}
			}
			p {
        padding: 1.5rem 0 4rem;
			}
			address {
				.address_info {
					font-weight: normal;
					dt {
						font-weight: normal;
						float:left;
						&::after {
							content: "";
							display: block;
							clear: both;
						}
					}
					dd.sp{
						text-indent: 0;
						@include sp {
							margin-left: 6.5vw;
						};
						@include tab {
							margin-left:5vw;
						};
					}
				}
			}
		}
	}
}

/* ------------------------------------------------------------
	8.thanks_page
------------------------------------------------------------ */

.thanks_container {
	width: 100%;
	margin: 0 auto;
  padding: 10rem 0;
	background: #fff;
	@include tab {
		padding: 20rem 0 10rem;
	};
	@include ssp {
    padding: 35vw 0 0;
	};
	.contact {
		.contact_title {
			text-align: center;
			font-family: "futura-pt", sans-serif;
			h2 {
				margin: 4vw 0 3vw auto;
				font-size: 33px;
				font-size: 3.3rem;
				display: inline;
				@include tab {
					font-size: 33px;
					font-size: 3.3rem;
				};
				@include ssp {
					font-size: 30px;
					font-size: 3rem;
				};
				small {
					margin-bottom: -5rem;
					font-size: 1.4rem;
					font-size: 14px;
					font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
					display: block;
				}
			}
		}
		.mail_box {
	    margin-top: 10rem;
			text-align: center;
			@include tab {
		    margin-top: 5rem;
			};
			@include ssp {
		    margin-top: 5vw;
			};
			h3 {
		    margin-bottom: 5rem;
				display: inline-block;
				font-size: 2rem;
				font-size: 20px;
				font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
				font-weight: bold;
				position: relative;
				@include tab {
					margin-bottom: 6vw;
					font-size: 33px;
			    font-size: 3.3rem;
				};
				@include ssp {
					margin-bottom: 6vw;
			    font-size: 7.5vw;
				};
				&::after {
					content: "";
					width: 95%;
					margin: 0 auto;
					position: absolute;
					bottom: -0.5vw;
					border-bottom: 2px solid #000;
					left: 0.25vw;
					@include tab {
						border-bottom: none;
					};
					@include ssp {
						border-bottom: none;
					};
				}
				span {
					@include tab {
						border-bottom: 3px solid #000;
					};
					@include ssp {
						border-bottom: 3px solid #000;
					};
				}
			}
			p {
		    margin-bottom: 7rem;
				font-size: 1.6rem;
				font-size: 16px;
				font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
				@include ssp {
	        margin-bottom: 12vw;
			    font-size: 4.5vw;
				};
			}
			a {
				width: 27rem;
				margin: 0 auto 5rem;
				height: 6rem;
				position: relative;
				display: block;
				background: #000;
				line-height: 6rem;
				color: #fff;
				font-size: 1.8rem;
				font-size: 18px;
				font-weight: bold;
				font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
		    text-decoration: none;
		    text-align: center;
		    z-index: 10;
				@include ssp {
			    width: 85vw;
					height: 18vw;
			    font-size: 5vw;
					line-height: 18vw;
				};
				span {
					width: 2rem;
					height: 2rem;
					padding: 1rem;
					position: absolute;
					top: 20px;
					right: 20px;
					display: block;
					background: url(../img/icon.png) -30rem -4.6rem/38rem auto no-repeat;
					@include tab {
						width: 3rem;
				    height: 3rem;
				    padding: 1rem;
				    position: absolute;
				    top: 15px;
				    right: 15px;
				    display: block;
				    background: url(../img/icon.png) -39vw -5.5vw/50vw auto no-repeat;
					}
					@include ssp {
						width: 4vw;
				    height: 8vw;
				    top: 5vw;
				    right: 8vw;
				    background: url(../img/icon.png) -80vw -11vw/100vw auto no-repeat;
					}
				}
			}
			a.hov_btn:before {
				content: "";
		    position: absolute;
		    display: block;
		    background: #fff;
		    width: 0;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    -webkit-transition: .4s ease 0s;
		    transition: .4s ease 0s;
		    z-index: -1;
		    border: solid 1px #000;
			}
			a.hov_btn:hover {
				color: #000;
				span {
					color: #000;
					background: url(../img/icon.png) -30rem -7.7rem/38rem auto no-repeat;
					/*
					@include tab {
						width: 3rem;
				    height: 3rem;
				    padding: 1rem;
				    position: absolute;
				    top: 15px;
				    right: 15px;
				    display: block;
				    background: url(../img/icon.png) -39vw -9.5vw/50vw auto no-repeat;
					}
					@include ssp {
						width: 4vw;
				    height: 8vw;
				    top: 5vw;
				    right: 8vw;
				    background: url(../img/icon.png) -80vw -35vw/100vw auto no-repeat;
					}
					*/
				}
			}
			a.hov_btn:hover:before {
				width: 100%;
			}
		}
	}
}

/* ------------------------------------------------------------
	footer
------------------------------------------------------------ */

footer#anchor {
	width: 75%;
	margin: 0 auto;
	padding: 4rem 0;
	text-align: center;
	font-size: 1.4rem;
	font-size: 14px;
	position: relative;
  border-top: 1px solid;
  font-family: "futura-pt", sans-serif;
	color: #666;
	@include ssp {
		width: 90%;
	  padding: 7.5rem 0 5rem;
		text-align: left;
	};
	#link {
		font-size: 14px;
		@include ssp {
			font-size: 3.5vw;
		};
	}
	.pravacy_link {
		margin-top: -10px;
		position: absolute;
		top: 50%;
    right: 3.5vw;
		display: block;
		@include tab {
			right: 8vw;
		};
		@include ssp {
			margin-top: 0;
      top: 8vw;
			right: 17.2vw;
			left: 3vw;
		};
		&::before {
			content: "";
			width: 0.1rem;
			height: 1.2rem;
			position: absolute;
			top: 0.4vw;
			left: -1vw;
			background: #666;
			@include ssp {
				height: 1.2rem;
		    top: 1.8vw;
		    left: -2.8vw;
			};
		}
		&::after {
			content: "";
			width: 0.1rem;
			height: 1.2rem;
			position: absolute;
			top: 0.4vw;
			right: -1vw;
			background: #666;
			@include ssp {
				height: 1.2rem;
		    top: 1.8vw;
		    left: 29vw;
			};
		}
		.hv_link {
			color: #666;
			font-size: 14px;
			@include tab {
				font-size: 14px;
			};
			@include ssp {
				font-size: 3.8vw;
			};
			&::after {
				content: '';
				width: 0;
				height: 0.1px;
				display: block;
				background: #666;
				transition: width .3s;
			}
		}
		.hv_link:hover {
			&:hover{
				text-decoration: none;
			}
		}
	}
}


/* ------------------------------------------------------------
	other
------------------------------------------------------------ */

input[type=text] {
	font-size: 16px;
	transform: scale(1);
}


/* ------------------------------------------------------------
	orientation: landscape
------------------------------------------------------------ */

@media screen and (orientation: landscape) {
	@include sp {
		/* header
		-----------------------*/
		.header {
			h1.logo {
				margin: 0;
				height: 8vw;
				a {
					width: 25%;
			    top: 2.5vw;
					left: 3vw;
				}
			}
			.button_container {
		    top: 3.5vw;
			}
		}
		/* main
		-----------------------*/
		main {
			.main_container {
		    height: 130vw !important;
				.main_img_container {
					.main_img {
						height: 130vw;
					}
				}
			}
			.servic_container {
				padding-top: 40vh !important;
				.service {
					h2 {
						small {
							margin-bottom: -6vw;
						}
					}
				}
				.service_info_area {
					.info_box_container {
						.info_box {
							&::before	{
						    top: 138vw;
							}
						}
					}
				}
			}
			.service_text_box{
				.service_text {
					h3.main_title {
						.service_b {
							height: 20vw;
						}
					}
				}
				.circle_c {
					height: 30rem;
				}
			}
		}

		/* media
		-----------------------*/
		.media_container {
			.media_text_box {
				h2 {
					small {
				    margin-bottom: -6vw;
					}
				}
				.media_content {
					.media_text {
						h3 {
							span.media_c {
								margin-bottom: 95vw;
							}
						}
					}
				}
			}
		}

		/* company_container
		-----------------------*/
		.company_container {
			h2 {
				small {
				  margin-bottom: -6vw;
				}
			}
			.circle_j {
				top: 49vw;
			}
			.line_f {
		    top: 68vw;
			}
		}

		/* contact_container
		-----------------------*/
		.contact_container{
			.contact {
				.contact_title {
					h2 {
						small {
						 margin-bottom: -6vw;
						}
					}
				}
			}
		}

		/* overlay
		-----------------------*/
		.overlay ul {
      margin: 5vw auto 0;
		}
		.overlay ul li a {
			font-size: 4vw;
		}
		/*-----------------------
		 contact
		-----------------------*/
		.contact_form_container,
		.privacy_container,
		.contact_confirm_container,
		.thanks_container {
	    padding: 20vw 0 8vw;
		}

		.privacy_container .privacy .personal_info address .address_info dd.sp {
			margin-left: 5.5vw;
		}

		.thanks_container .contact .mail_box h3 {
			font-size: 5.5vw;
		}
		.thanks_container .contact .mail_box p {
		  font-size: 3.5vw;
		}
		.thanks_container .contact .mail_box a {
			width: 60vw;
			height: 15vw;
			font-size: 3.5vw;
			line-height: 15vw;
		}
		.thanks_container .contact .mail_box a span {
	    width: 0;
	    height: 8vw;
	    top: 3.8vw;
		}

		/*-----------------------
		 media
		-----------------------*/
		.media_page_container .media_text_area .text_box_R p {
	    font-size: 3vw;
		}
		.media_page_container .media_box .media_title h2 small {
		  margin-bottom: -9vw;
		}
		.service_model figure img {
	    width: 70%;
		}
		.service_model .service_model_list ul li {
	    font-size: 3vw;
		}
		.flow_model .flow_category ul li div p {
	    font-size: 3vw;
		}

		/*-----------------------
		 footer
		-----------------------*/
		footer#anchor .pravacy_link {
			top: 2vw;
			&::before {
		    height: 3.2vw;
			}
			&::after {
		    height: 3.2vw;
			}
		}
	};
}
